
.oh-table {
    width: 100%;
    height: 100%;
    text-align: center;
    word-break: normal;
}
.oh-table th {
    padding: 0;
    margin: 0;
}

.oh-table, .oh-table td {
    border-collapse: collapse;
    background-clip: padding-box;
    border-right: 1px solid #ccc;
}

.oh-timecell {
    background-color: white;
    position: relative;
    box-sizing: border-box;
}

.oh-table th {
    font-weight: bold;
    font-size: medium;
    background-color: #ddd;
}

.oh-table td {
    vertical-align: top;
}

.oh-timecell:hover {
    background-color: var(--catch-detail-color) !important;
}


.oh-timecell-selected {
    background-color: var(--catch-detail-color);
}


.oh-timecell-half {
    border-bottom: 1px solid #ddd;
    background-color: var(--subtle-detail-color);
}

.oh-timecell-half.oh-timecell-selected {
    background-color: var(--catch-detail-color);
}

.oh-table tr {
    background: #ddd;
}

.oh-left-col {
    border-bottom: 1px solid #aaa;
    margin: 0;
    margin-left: 10px;
    width: 0.5em;
    font-size: large;
    padding: 0;
    padding-right: 0.2em;
}

.oh-timecell-0 {
    border-left: 10px solid rgba(0, 0, 0, 0);
}

.oh-timecell-6 {
    border-right: 10px solid rgba(0, 0, 0, 0);
}

.oh-timecol-selected {
    border-right: var(--catch-detail-color);
}

.oh-timecol-selected > span {
    background-color: var(--catch-detail-color);
    color: var(--background-color);
    width: 100%;
    display: block;
}

.oh-timecol-selected-round-left > span {
    border-top-left-radius: 5em;
    border-bottom-left-radius: 5em;
}

.oh-timecol-selected-round-right > span {
    border-top-right-radius: 5em;
    border-bottom-right-radius: 5em;
}

.oh-timerow-selected .oh-timecell-0 {
    border-left: 10px solid var(--catch-detail-color) !important;
}

.oh-timerow-selected .oh-timecell-6 {
    border-right: 10px solid var(--catch-detail-color) !important;
}


.oh-draggable-header {
    background-color: blue;
    height: 0.5em;
}

.oh-timerange {
    border-radius: 0.5em;
    margin-left: 2px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 4px);
    background: var(--catch-detail-color);
    z-index: 1;
    box-sizing: border-box;
}

.oh-timerange-inner {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    justify-content: space-between;
    align-content: center;
    height: 100%;
    overflow-y: hidden;
}

.oh-timerange-inner input {
     width: 100%;
     box-sizing: border-box;
 }

.oh-timerange-inner-small {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    width:100%;
}

.oh-timerange-inner-small input {
    width: min-content;
    box-sizing: border-box;
}

.oh-delete-range{
    width: 1.5em;
    height: 1.5em;
    background:black;
    border-radius:0.75em;
}

.oh-delete-range img {
    height: 100%;
    max-width: 2em;
}

.oh-timerange-label {
    color: white;
}


/**** Opening hours visualization table ****/

.ohviz-table {
    word-break: normal;
}

.ohviz-range {
    display: block;
    background: #99e7ff;
    position: absolute;
    left: 0;
    top: 5%;
    height: 85%;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    text-align: center;
    font-size: smaller;
}

.ohviz-today .ohviz-range {
    border: 1.5px solid black;

}

.ohviz-day-off {
    display: block;
    background: repeating-linear-gradient(
            45deg,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0) 10px,
            rgba(102, 207, 255, 0.5) 10px,
            rgba(102, 207, 255, 0.5) 20px
    );
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    color: black;
    font-weight: bold;
    text-align: center;
    border-radius: 1em;
}

.ohviz-today .ohviz-day-off {
    display: block;
    background: repeating-linear-gradient(
            45deg,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0) 10px,
            rgb(153, 231, 255) 10px,
            #99e7ff 20px
    );
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    color: black;
    font-weight: bold;
    text-align: center;
    border-radius: 1em;
}

.ohviz-now {
    position: absolute;
    top: 0;
    margin: 0;
    height: 100%;
    border: 1px solid black;
    box-sizing: border-box
}

.ohviz-line {
    position: absolute;
    top: 0;
    margin: 0;
    height: 100%;
    border-left: 1px solid #ccc;
    box-sizing: border-box
}


.ohviz-time-indication > div {
    position: relative;
    background-color: white;
    left: -50%;
    padding-left: 0.3em;
    padding-right: 0.3em;
    font-size: smaller;
    border-radius: 0.3em;
    border: 1px solid #ccc;
    word-break: initial;

}

.ohviz-time-indication {
    position: absolute;
    top: 0;
    margin: 0;
    height: 100%;
    box-sizing: border-box;
}


.ohviz-today {
    background-color: var(--subtle-detail-color);
}

.ohviz-weekday {
    padding-left: 0.5em;
    word-break: normal;
}


.ohviz {
    border-collapse: collapse;
}

.ohviz-container {
    border: 0.5em solid var(--subtle-detail-color);
    border-radius: 1em;
    display: block;
}

.ohviz-closed {
    padding: 1em;
    background-color: #eee;
    border-radius: 1em;
    display: block;
}